<template>
  <v-card
    class="text-center"
    max-height="450"
    min-height="450"
    flat
    color="grey lighten-4"
  >
    <v-btn
      fab
      class="mt-5"
      v-if="mini"
      icon
      @click.stop="switchMini"
    >
      <v-icon color="primary">fas fa-calculator</v-icon>
    </v-btn>
    <v-navigation-drawer
      v-else
      color="grey lighten-4"
      width="100%"
      class="pt-0"
      floating
      :mini-variant.sync="mini"
    >
      <v-list-item>
        <v-list-item-avatar>
          <v-btn
            icon
            @click.stop="switchMini"
          >
            <v-icon>fas fa-angle-left</v-icon>
          </v-btn>
        </v-list-item-avatar>

        <v-list-item-title class="subtitle-2 secondary--text">
          Calcular preço de venda
        </v-list-item-title>
      </v-list-item>

      <v-list
        v-show="!mini"
        dense
      >
        <v-list-item
          v-for="(row, idx) in rows"
          :key="idx"
        >
          <v-list-item-content>
            <v-list-item-title class="d-flex align-center pa-0">
              <v-text-field
                v-model="row.quantity"
                class="my-2"
                label="Qtd."
                outlined
                rounded
                dense
                hide-details
              />
              <v-btn
                icon
                @click="removeRow(row)"
              >
                <v-icon
                  small
                  color="error"
                  >fas fa-times</v-icon
                >
              </v-btn>
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item v-show="!mini">
          <v-btn
            :disabled="!selectedPrint"
            :class="!selectedPrint ? 'grey' : 'primary'"
            dark
            icon
            @click="addRow"
          >
            <v-icon small>fas fa-plus</v-icon>
          </v-btn>
        </v-list-item>
        <v-list-item v-if="isLoading">
          <v-progress-linear indeterminate />
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
    <v-footer
      v-if="!selectedPrint"
      class="error white--text justify-center"
      absolute
      bottom
    >
      <v-tooltip
        v-if="mini"
        right
      >
        <template v-slot:activator="{ on, attrs }">
          <v-icon
            large
            color="white"
            v-on="on"
            v-bind="attrs"
            >mdi-information-outline</v-icon
          >
        </template>
        <span>
          Para calcular o preço de venda é necessário <br />
          selecionar uma gravação no filtro
        </span>
      </v-tooltip>
      <span v-else>
        Para calcular o preço de venda é necessário selecionar uma gravação no
        filtro
      </span>
    </v-footer>
  </v-card>
</template>

<script>
export default {
  props: {
    selectedPrint: Object,
    isLoading: Boolean,
  },
  data: () => ({
    rows: [],
    mini: false,
  }),
  watch: {
    rows(oldValue, newValue) {
      this.$emit('addRow', newValue)
    },
  },
  methods: {
    addRow() {
      this.rows.push({})
    },
    removeRow(row) {
      let idx = this.rows.indexOf(row)
      this.rows.splice(idx, 1)
    },
    switchMini() {
      this.mini = !this.mini
      this.$emit('switchMini', this.mini)
    },
  },
}
</script>